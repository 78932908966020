import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {ACCOUNTS, ACCOUNTS_API_URL, GET_ALL_ACCOUNTS_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  AccountsErrors       : {},
  permission: permission?.accounts ?? [],
  AccountsShowMessage  : null,
  AccountsResult       : [],
  AccountsButtonSpinner : false,
  AccountsTableLoading : true,
  AccountsAddDrawer    : false,
  AccountsEditData     : [],
  AccountsButtonAndModelLabel  : setLocale('accounts.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewAccountsData: [],
    ViewAccountsLoader: true,
    DrawerStatus: 0,
};

export const createAccounts = createAsyncThunk(
    "createAccounts",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, ACCOUNTS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getAccounts = createAsyncThunk(
    "getAccounts",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_ACCOUNTS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewAccounts = createAsyncThunk(
  "viewAccounts",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, ACCOUNTS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteAccounts = createAsyncThunk(
  "deleteAccounts",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ACCOUNTS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageAccountsSlice = createSlice({
  name: ACCOUNTS,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.AccountsErrors = {};
    },
    AccountsAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.AccountsButtonSpinner = false;
      }
      state.AccountsAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.AccountsEditData    = [];
      state.AccountsButtonAndModelLabel = setLocale('accounts.add');
    },
    AccountsEditWithDrawerStatus: (state, action) => {
      state.AccountsAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.AccountsEditData = action.payload.data;
      state.AccountsButtonAndModelLabel = setLocale('accounts.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAccounts.pending, (state, action) => {
        state.DrawerStatus = 0
        state.AccountsButtonSpinner = true;
      }).addCase(createAccounts.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.AccountsButtonSpinner = false;
        state.AccountsErrors = {}
      }).addCase(createAccounts.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.AccountsShowMessage = true;
        state.AccountsButtonSpinner = false;
        state.AccountsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getAccounts.pending, (state, action) => {
        state.AccountsButtonSpinner = true;
        state.AccountsTableLoading = true;
      }).addCase(getAccounts.fulfilled, (state, action) => {
        state.AccountsButtonSpinner = false;
        state.AccountsTableLoading = false;
        state.AccountsResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getAccounts.rejected, (state, action) => {
        state.AccountsShowMessage = true; // Set the showMessage flag to display the errors
        state.AccountsButtonSpinner = false;
        state.AccountsTableLoading = false;
        state.AccountsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewAccounts.pending, (state, action) => {
        state.ViewAccountsLoader = true;
        state.ViewAccountsData = [];
      }).addCase(viewAccounts.fulfilled, (state, action) => {
        state.ViewAccountsLoader = false;
        state.ViewAccountsData = action.payload;
      }).addCase(viewAccounts.rejected, (state, action) => {
        state.ViewAccountsLoader = false;
        state.ViewAccountsData = [];
      })
      .addCase(deleteAccounts.pending, (state, action) => {
        state.AccountsTableLoading = true;
      }).addCase(deleteAccounts.fulfilled, (state, action) => {
        state.AccountsTableLoading = false;
      }).addCase(deleteAccounts.rejected, (state, action) => {
        state.AccountsTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, AccountsAddDrawerStatus, AccountsEditWithDrawerStatus, updateSortFilters } = manageAccountsSlice.actions;

export default manageAccountsSlice.reducer;
