import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {COURSE_CATEGORIES, COURSE_CATEGORIES_API_URL, GET_ALL_COURSE_CATEGORIES_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  CourseCategoriesErrors       : {},
  permission: permission?.course_categories ?? [],
  CourseCategoriesShowMessage  : null,
  CourseCategoriesResult       : [],
  CourseCategoriesButtonSpinner : false,
  CourseCategoriesTableLoading : true,
  CourseCategoriesAddDrawer    : false,
  CourseCategoriesEditData     : [],
  CourseCategoriesButtonAndModelLabel  : setLocale('coursecategories.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewCourseCategoriesData: [],
    ViewCourseCategoriesLoader: true,
    DrawerStatus: 0,
};

export const createCourseCategories = createAsyncThunk(
    "createCourseCategories",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, COURSE_CATEGORIES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getCourseCategories = createAsyncThunk(
    "getCourseCategories",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_COURSE_CATEGORIES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewCourseCategories = createAsyncThunk(
  "viewCourseCategories",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, COURSE_CATEGORIES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteCourseCategories = createAsyncThunk(
  "deleteCourseCategories",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, COURSE_CATEGORIES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageCourseCategoriesSlice = createSlice({
  name: COURSE_CATEGORIES,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.CourseCategoriesErrors = {};
    },
    CourseCategoriesAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.CourseCategoriesButtonSpinner = false;
      }
      state.CourseCategoriesAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.CourseCategoriesEditData    = [];
      state.CourseCategoriesButtonAndModelLabel = setLocale('coursecategories.add');
    },
    CourseCategoriesEditWithDrawerStatus: (state, action) => {
      state.CourseCategoriesAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.CourseCategoriesEditData = action.payload.data;
      state.CourseCategoriesButtonAndModelLabel = setLocale('coursecategories.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCourseCategories.pending, (state, action) => {
        state.DrawerStatus = 0
        state.CourseCategoriesButtonSpinner = true;
      }).addCase(createCourseCategories.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.CourseCategoriesButtonSpinner = false;
        state.CourseCategoriesErrors = {}
      }).addCase(createCourseCategories.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.CourseCategoriesShowMessage = true;
        state.CourseCategoriesButtonSpinner = false;
        state.CourseCategoriesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getCourseCategories.pending, (state, action) => {
        state.CourseCategoriesButtonSpinner = true;
        state.CourseCategoriesTableLoading = true;
      }).addCase(getCourseCategories.fulfilled, (state, action) => {
        state.CourseCategoriesButtonSpinner = false;
        state.CourseCategoriesTableLoading = false;
        state.CourseCategoriesResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getCourseCategories.rejected, (state, action) => {
        state.CourseCategoriesShowMessage = true; // Set the showMessage flag to display the errors
        state.CourseCategoriesButtonSpinner = false;
        state.CourseCategoriesTableLoading = false;
        state.CourseCategoriesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewCourseCategories.pending, (state, action) => {
        state.ViewCourseCategoriesLoader = true;
        state.ViewCourseCategoriesData = [];
      }).addCase(viewCourseCategories.fulfilled, (state, action) => {
        state.ViewCourseCategoriesLoader = false;
        state.ViewCourseCategoriesData = action.payload;
      }).addCase(viewCourseCategories.rejected, (state, action) => {
        state.ViewCourseCategoriesLoader = false;
        state.ViewCourseCategoriesData = [];
      })
      .addCase(deleteCourseCategories.pending, (state, action) => {
        state.CourseCategoriesTableLoading = true;
      }).addCase(deleteCourseCategories.fulfilled, (state, action) => {
        state.CourseCategoriesTableLoading = false;
      }).addCase(deleteCourseCategories.rejected, (state, action) => {
        state.CourseCategoriesTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, CourseCategoriesAddDrawerStatus, CourseCategoriesEditWithDrawerStatus, updateSortFilters } = manageCourseCategoriesSlice.actions;

export default manageCourseCategoriesSlice.reducer;
