import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CLASS, CLASS_API_URL, GET_ALL_CLASS_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ClassErrors: {},
  permission: permission?.class ?? [],
  ClassShowMessage: null,
  ClassResult: [],
  ClassButtonSpinner: false,
  ClassTableLoading: true,
  ClassAddDrawer: false,
  ClassEditData: [],
  ClassButtonAndModelLabel: setLocale('class.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewClassData: [],
  ViewClassLoader: true,
};

export const createClass = createAsyncThunk(
  "createClass",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, CLASS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getClass = createAsyncThunk(
  "getClass",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_CLASS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewClass = createAsyncThunk(
  "viewClass",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, CLASS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteClass = createAsyncThunk(
  "deleteClass",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, CLASS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageClassSlice = createSlice({
  name: CLASS,
  initialState,
  reducers: {
    ClassAddDrawerStatus: (state, action) => {
      state.ClassErrors = {};
      state.ClassAddDrawer = action.payload;
      state.ClassEditData = [];
      state.ClassButtonAndModelLabel = setLocale('class.add');
    },
    ClassEditWithDrawerStatus: (state, action) => {
      state.ClassErrors = {};
      state.ClassAddDrawer = true;
      state.ClassEditData = action.payload;
      state.ClassButtonAndModelLabel = setLocale('class.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createClass.pending, (state, action) => {
        state.ClassButtonSpinner = true;
      }).addCase(createClass.fulfilled, (state, action) => {
        state.ClassButtonSpinner = false;
        state.ClassErrors = {}
      }).addCase(createClass.rejected, (state, action) => {
        state.ClassShowMessage = true;
        state.ClassButtonSpinner = false;
        state.ClassErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getClass.pending, (state, action) => {
        state.ClassButtonSpinner = true;
        state.ClassTableLoading = true;
      }).addCase(getClass.fulfilled, (state, action) => {
        state.ClassButtonSpinner = false;
        state.ClassTableLoading = false;
        state.ClassResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page
        };
      }).addCase(getClass.rejected, (state, action) => {
        state.ClassShowMessage = true; // Set the showMessage flag to display the errors
        state.ClassButtonSpinner = false;
        state.ClassTableLoading = false;
        state.ClassErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewClass.pending, (state, action) => {
        state.ViewClassLoader = true;
        state.ViewClassData = [];
      }).addCase(viewClass.fulfilled, (state, action) => {
        state.ViewClassLoader = false;
        state.ViewClassData = action.payload;
      }).addCase(viewClass.rejected, (state, action) => {
        state.ViewClassLoader = false;
        state.ViewClassData = [];
      })
      .addCase(deleteClass.pending, (state, action) => {

      }).addCase(deleteClass.fulfilled, (state, action) => {

      }).addCase(deleteClass.rejected, (state, action) => {

      });
  },
});

export const { ClassAddDrawerStatus, ClassEditWithDrawerStatus, updateSortFilters } = manageClassSlice.actions;

export default manageClassSlice.reducer;
