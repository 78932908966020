import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {SIBLING_REPORT, SIBLING_REPORT_API_URL, GET_ALL_SIBLING_REPORT_API_URL,EXPORT_SIBLING_REPORT_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  SiblingReportErrors       : {},
  permission: permission?.sibling_report ?? [],
  SiblingReportShowMessage  : null,
  SiblingReportResult       : [],
  SiblingReportButtonSpinner : false,
  SiblingReportTableLoading : true,
  SiblingReportAddDrawer    : false,
  SiblingReportEditData     : [],
  SiblingReportButtonAndModelLabel  : setLocale('siblingreport.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewSiblingReportData: [],
    ViewSiblingReportLoader: true,
    DrawerStatus: 0,
    exportButtonLoader: false,
};

export const createSiblingReport = createAsyncThunk(
    "createSiblingReport",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, SIBLING_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getSiblingReport = createAsyncThunk(
    "getSiblingReport",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_SIBLING_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewSiblingReport = createAsyncThunk(
  "viewSiblingReport",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, SIBLING_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteSiblingReport = createAsyncThunk(
  "deleteSiblingReport",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, SIBLING_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const exportSiblingReport = createAsyncThunk('exportSiblingReport', async (data) => {
  try {
    return await CommonService.postData(data, EXPORT_SIBLING_REPORT_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
});

export const manageSiblingReportSlice = createSlice({
  name: SIBLING_REPORT,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.SiblingReportErrors = {};
    },
    SiblingReportAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.SiblingReportButtonSpinner = false;
      }
      state.SiblingReportAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.SiblingReportEditData    = [];
      state.SiblingReportButtonAndModelLabel = setLocale('siblingreport.add');
    },
    SiblingReportEditWithDrawerStatus: (state, action) => {
      state.SiblingReportAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.SiblingReportEditData = action.payload.data;
      state.SiblingReportButtonAndModelLabel = setLocale('siblingreport.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSiblingReport.pending, (state, action) => {
        state.DrawerStatus = 0
        state.SiblingReportButtonSpinner = true;
      }).addCase(createSiblingReport.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.SiblingReportButtonSpinner = false;
        state.SiblingReportErrors = {}
      }).addCase(createSiblingReport.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.SiblingReportShowMessage = true;
        state.SiblingReportButtonSpinner = false;
        state.SiblingReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getSiblingReport.pending, (state, action) => {
        state.SiblingReportButtonSpinner = true;
        state.SiblingReportTableLoading = true;
      }).addCase(getSiblingReport.fulfilled, (state, action) => {
        state.SiblingReportButtonSpinner = false;
        state.SiblingReportTableLoading = false;
        state.SiblingReportResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getSiblingReport.rejected, (state, action) => {
        state.SiblingReportShowMessage = true; // Set the showMessage flag to display the errors
        state.SiblingReportButtonSpinner = false;
        state.SiblingReportTableLoading = false;
        state.SiblingReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewSiblingReport.pending, (state, action) => {
        state.ViewSiblingReportLoader = true;
        state.ViewSiblingReportData = [];
      }).addCase(viewSiblingReport.fulfilled, (state, action) => {
        state.ViewSiblingReportLoader = false;
        state.ViewSiblingReportData = action.payload;
      }).addCase(viewSiblingReport.rejected, (state, action) => {
        state.ViewSiblingReportLoader = false;
        state.ViewSiblingReportData = [];
      })
      .addCase(deleteSiblingReport.pending, (state, action) => {
        state.SiblingReportTableLoading = true;
      }).addCase(deleteSiblingReport.fulfilled, (state, action) => {
        state.SiblingReportTableLoading = false;
      }).addCase(deleteSiblingReport.rejected, (state, action) => {
        state.SiblingReportTableLoading = false;
      })
      .addCase(exportSiblingReport.pending, (state, action) => {
        state.exportButtonLoader = true;
      }).addCase(exportSiblingReport.fulfilled, (state, action) => {
        const fileUrl = action.payload.file_url; // Get the file URL from the response

        // Create a hidden anchor tag to initiate download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'sibling_report.xlsx'); // Specify the file name

        // Append the anchor to the body, trigger the click, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        state.exportButtonLoader = false;
      }).addCase(exportSiblingReport.rejected, (state, action) => {
        state.exportButtonLoader = false;
      });
  },
});

export const { onCloseError, setColumnSearch, SiblingReportAddDrawerStatus, SiblingReportEditWithDrawerStatus, updateSortFilters } = manageSiblingReportSlice.actions;

export default manageSiblingReportSlice.reducer;
