import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PROCUREMENT_ITEM, PROCUREMENT_ITEM_API_URL, GET_ALL_PROCUREMENT_ITEM_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ProcurementItemErrors: {},
  permission: permission?.procurement_item ?? [],
  ProcurementItemShowMessage: null,
  ProcurementItemResult: [],
  ProcurementItemButtonSpinner: false,
  ProcurementItemTableLoading: true,
  ProcurementItemAddDrawer: false,
  ProcurementItemEditData: [],
  ProcurementItemButtonAndModelLabel: setLocale('procurementitem.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewProcurementItemData: [],
  ViewProcurementItemLoader: true,
  DrawerStatus: 0,
};

export const createProcurementItem = createAsyncThunk(
  "createProcurementItem",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, PROCUREMENT_ITEM_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getProcurementItem = createAsyncThunk(
  "getProcurementItem",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_PROCUREMENT_ITEM_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewProcurementItem = createAsyncThunk(
  "viewProcurementItem",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, PROCUREMENT_ITEM_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteProcurementItem = createAsyncThunk(
  "deleteProcurementItem",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, PROCUREMENT_ITEM_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageProcurementItemSlice = createSlice({
  name: PROCUREMENT_ITEM,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.RelationshipErrors = {};
    },
    ProcurementItemAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ProcurementItemButtonSpinner = false;
      }
      state.ProcurementItemAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ProcurementItemEditData = [];
      state.ProcurementItemButtonAndModelLabel = setLocale('procurementitem.add');
    },
    ProcurementItemEditWithDrawerStatus: (state, action) => {
      state.ProcurementItemAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ProcurementItemEditData = action.payload.data;
      state.ProcurementItemButtonAndModelLabel = setLocale('procurementitem.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProcurementItem.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ProcurementItemButtonSpinner = true;
      }).addCase(createProcurementItem.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ProcurementItemButtonSpinner = false;
        state.ProcurementItemErrors = {}
      }).addCase(createProcurementItem.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ProcurementItemShowMessage = true;
        state.ProcurementItemButtonSpinner = false;
        state.ProcurementItemErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getProcurementItem.pending, (state, action) => {
        state.ProcurementItemButtonSpinner = true;
        state.ProcurementItemTableLoading = true;
      }).addCase(getProcurementItem.fulfilled, (state, action) => {
        state.ProcurementItemButtonSpinner = false;
        state.ProcurementItemTableLoading = false;
        state.ProcurementItemResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(getProcurementItem.rejected, (state, action) => {
        state.ProcurementItemShowMessage = true; // Set the showMessage flag to display the errors
        state.ProcurementItemButtonSpinner = false;
        state.ProcurementItemTableLoading = false;
        state.ProcurementItemErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewProcurementItem.pending, (state, action) => {
        state.ViewProcurementItemLoader = true;
        state.ViewProcurementItemData = [];
      }).addCase(viewProcurementItem.fulfilled, (state, action) => {
        state.ViewProcurementItemLoader = false;
        state.ViewProcurementItemData = action.payload;
      }).addCase(viewProcurementItem.rejected, (state, action) => {
        state.ViewProcurementItemLoader = false;
        state.ViewProcurementItemData = [];
      })
      .addCase(deleteProcurementItem.pending, (state, action) => {
        state.ProcurementItemTableLoading = true;
      }).addCase(deleteProcurementItem.fulfilled, (state, action) => {
        state.ProcurementItemTableLoading = false;
      }).addCase(deleteProcurementItem.rejected, (state, action) => {
        state.ProcurementItemTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, ProcurementItemAddDrawerStatus, ProcurementItemEditWithDrawerStatus, updateSortFilters } = manageProcurementItemSlice.actions;

export default manageProcurementItemSlice.reducer;
