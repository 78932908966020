import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {TRANSPORTATION_REPORT, TRANSPORTATION_REPORT_API_URL, GET_ALL_TRANSPORTATION_REPORT_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  TransportationReportErrors       : {},
  permission: permission?.transportation_report ?? [],
  TransportationReportShowMessage  : null,
  TransportationReportResult       : [],
  TransportationReportButtonSpinner : false,
  TransportationReportTableLoading : true,
  TransportationReportAddDrawer    : false,
  TransportationReportEditData     : [],
  TransportationReportButtonAndModelLabel  : setLocale('transportationreport.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewTransportationReportData: [],
    ViewTransportationReportLoader: true,
    DrawerStatus: 0,
};

export const createTransportationReport = createAsyncThunk(
    "createTransportationReport",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, TRANSPORTATION_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getTransportationReport = createAsyncThunk(
    "getTransportationReport",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_TRANSPORTATION_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewTransportationReport = createAsyncThunk(
  "viewTransportationReport",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, TRANSPORTATION_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteTransportationReport = createAsyncThunk(
  "deleteTransportationReport",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, TRANSPORTATION_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageTransportationReportSlice = createSlice({
  name: TRANSPORTATION_REPORT,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.TransportationReportErrors = {};
    },
    TransportationReportAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.TransportationReportButtonSpinner = false;
      }
      state.TransportationReportAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.TransportationReportEditData    = [];
      state.TransportationReportButtonAndModelLabel = setLocale('transportationreport.add');
    },
    TransportationReportEditWithDrawerStatus: (state, action) => {
      state.TransportationReportAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.TransportationReportEditData = action.payload.data;
      state.TransportationReportButtonAndModelLabel = setLocale('transportationreport.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTransportationReport.pending, (state, action) => {
        state.DrawerStatus = 0
        state.TransportationReportButtonSpinner = true;
      }).addCase(createTransportationReport.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.TransportationReportButtonSpinner = false;
        state.TransportationReportErrors = {}
      }).addCase(createTransportationReport.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.TransportationReportShowMessage = true;
        state.TransportationReportButtonSpinner = false;
        state.TransportationReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getTransportationReport.pending, (state, action) => {
        state.TransportationReportButtonSpinner = true;
        state.TransportationReportTableLoading = true;
      }).addCase(getTransportationReport.fulfilled, (state, action) => {
        state.TransportationReportButtonSpinner = false;
        state.TransportationReportTableLoading = false;
        state.TransportationReportResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getTransportationReport.rejected, (state, action) => {
        state.TransportationReportShowMessage = true; // Set the showMessage flag to display the errors
        state.TransportationReportButtonSpinner = false;
        state.TransportationReportTableLoading = false;
        state.TransportationReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewTransportationReport.pending, (state, action) => {
        state.ViewTransportationReportLoader = true;
        state.ViewTransportationReportData = [];
      }).addCase(viewTransportationReport.fulfilled, (state, action) => {
        state.ViewTransportationReportLoader = false;
        state.ViewTransportationReportData = action.payload;
      }).addCase(viewTransportationReport.rejected, (state, action) => {
        state.ViewTransportationReportLoader = false;
        state.ViewTransportationReportData = [];
      })
      .addCase(deleteTransportationReport.pending, (state, action) => {
        state.TransportationReportTableLoading = true;
      }).addCase(deleteTransportationReport.fulfilled, (state, action) => {
        state.TransportationReportTableLoading = false;
      }).addCase(deleteTransportationReport.rejected, (state, action) => {
        state.TransportationReportTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, TransportationReportAddDrawerStatus, TransportationReportEditWithDrawerStatus, updateSortFilters } = manageTransportationReportSlice.actions;

export default manageTransportationReportSlice.reducer;
