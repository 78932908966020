import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
    IMPORT_STUDENT_GUARDIANS,
    IMPORT_SLICE, 
    IMPORT_STUDENTS,
    IMPORT_SIBLINGS,
    IMPORT_ATTENDANCE
} from 'constants/imports/index';
import CommonService from 'services/CommonService/CommonService';

export const initialState = {
    loading: false,
    errors: {},
    showMessage: false,
    unRegisteredStudents:[],
    invalidStudentsData:[],
    invalidSiblingsData:[]
 
}

export const importAttendance = createAsyncThunk('importAttendance', async (data) => {
    try {
        return await CommonService.postData(data, IMPORT_ATTENDANCE)
    } catch (err) {
        throw new Error(JSON.stringify(err?.response?.data?.errors))
    }
})
export const importStudentGuardians = createAsyncThunk('importStudentGuardians', async (data) => {
    try {
        return await CommonService.postData(data, IMPORT_STUDENT_GUARDIANS)
    } catch (err) {
        throw new Error(JSON.stringify(err?.response?.data?.errors))
    }
})
export const importStudents = createAsyncThunk('importStudents', async (data) => {
    try {
        return await CommonService.postData(data, IMPORT_STUDENTS)
    } catch (err) {
        throw new Error(JSON.stringify(err?.response?.data?.errors))
    }
})

export const importSiblings = createAsyncThunk('importSiblings', async (data) => {
    try {
        return await CommonService.postData(data, IMPORT_SIBLINGS)
    } catch (err) {
        throw new Error(JSON.stringify(err?.response?.data?.errors))
    }
})


export const importSlice = createSlice({
    name: IMPORT_SLICE,
    initialState,
    reducers: {
        clearErrors: (state) => {
            state.errors = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(importAttendance.pending, (state, action) => {
                state.loading = true
                state.errors = {}
                state.showMessage = false
            }).addCase(importAttendance.fulfilled, (state, action) => {                                
                state.loading = false
                state.errors = {}
                state.showMessage = false
                state.invalidStudentsData = action?.payload?.data
            }).addCase(importAttendance.rejected, (state, action) => {                
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message
                state.showMessage = true
                state.loading = false
            })
            .addCase(importStudentGuardians.pending, (state, action) => {
                state.loading = true
                state.errors = {}
                state.showMessage = false
                state.unRegisteredStudents =[]
            }).addCase(importStudentGuardians.fulfilled, (state, action) => {                                
                state.unRegisteredStudents = action?.payload?.data?.notRegisteredStudent
                state.loading = false
                state.errors = {}
                state.showMessage = false
            }).addCase(importStudentGuardians.rejected, (state, action) => {                
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message
                state.showMessage = true
                state.loading = false
            })
            .addCase(importStudents.pending, (state, action) => {
                state.loading = true
                state.errors = {}
                state.showMessage = false
            }).addCase(importStudents.fulfilled, (state, action) => {                                
                state.loading = false
                state.errors = {}
                state.showMessage = false
                state.invalidStudentsData = action?.payload?.data
            }).addCase(importStudents.rejected, (state, action) => {                
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message
                state.showMessage = true
                state.loading = false
            })

            .addCase(importSiblings.pending, (state, action) => {
                state.loading = true
                state.errors = {}
                state.showMessage = false
            }).addCase(importSiblings.fulfilled, (state, action) => {                                
                state.loading = false
                state.errors = {}
                state.showMessage = false
                state.invalidSiblingsData = action?.payload?.data
            }).addCase(importSiblings.rejected, (state, action) => {                
                state.errors = (action?.error?.message) ? JSON.parse(action?.error?.message) : action?.error?.message
                state.showMessage = true
                state.loading = false
            })

            
           
    },
})

export const {
    clearErrors
} = importSlice.actions

export default importSlice.reducer